@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  margin: 0px;
  padding: 0px;
}

ul {
  list-style-type: none;
}

.P1Container {
  background: #dff7fc;
  position: relative;
}

.P2Container1 {
  height: 100vh;
  width: 100%;
}

.P1container2 {
  position: absolute;
  top: 0;
  right: 0;
}

.P1container3 {
  position: absolute;
  bottom: 0;
  left: 0;
}

.p1Component1,
.p1Component2 {
  width: 100%;
}

@media only screen and (max-width: 503px) {
  .p1Component0 {
    width: 80%;
  }
}

.red {
  border: 1px solid red;
}

.p2container1 {
  height: 100vh;
}

.p2container2 {
  position: relative;
}

.p2text1 {
  position: absolute;
  top: 45%;
  left: 40%;
}

.p2text2 {
  position: absolute;
  top: 45%;
  left: 10%;
}

.Register_main_cont {
  background: #F5F5F5;
}

.Reg-right {
  /* background: white; */
  height: 100vh;
  /* border-top-left-radius: 18px;
  border-bottom-left-radius: 18px;
  box-shadow: 0px 0px 90px 0px #0000001A; */

}

.Reg-right-main {
  background: white;
  position: relative;
  border-top-left-radius: 18px;
  border-bottom-left-radius: 18px;
  /* box-shadow: 0px 0px 90px 0px #0000001A; */

}

.col-md-4 {
  padding: 0px;
}

.p2container3 {
  width: 600px;
}

.Reg-right-txt {
  color: #0575E6;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  position: absolute;
  top: 40px;
  right: 30px;
  cursor: pointer;
}

.Form_reg_div {
  padding: 24px;
}


.Form_reg_div h4 {
  color: #333333;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  line-height: 60px;
  font-size: 40px;
}

.Form_reg_div p {
  color: #333333;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  line-height: 27px;
  font-size: 18px;
}

.Reg_form_input_btn {
  padding: 16px 37px;
  width: 362px;
  box-shadow: 5px 5px 15px 0px #0000001A;
  border-radius: 10px;
  border: 1px solid #33333380;
  background: #1993FB;
  color: white;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
}

.Reg_form_input {
  padding: 16px 50px;
  width: 362px;
  box-shadow: 5px 5px 15px 0px #0000001A;
  border-radius: 10px;
  border: 1px solid #33333380;
}

.Reg_form_input::placeholder {
  padding-left: 14px;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
}

.Reg_input_div {
  position: relative;
}

.Reg_form_input_icon {
  position: absolute;
  top: 38%;
  left: 5%;
  opacity: 33%;
}

@media only screen and (max-width: 984px) and (min-width: 768px) {

  .Reg_form_input,
  .Reg_form_input_btn {
    width: 302px;
  }
}

@media only screen and (max-width: 768px) {
  .p2container1 {
    height: unset !important;
  }

  .Reg-right {
    height: unset !important;
    margin-top: 28px;
  }

  .Reg-right-txt {

    top: 32px;
    right: -32px;

  }
}

@media only screen and (max-width: 605px) {
  .p2container1 {
    width: 100% !important;
  }
  .p2container3 {
    width: 100%;
  }
}

@media only screen and (max-width: 375px) {

  .Reg-right-txt {
    color: #0575E6;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    position: absolute;
    top: 40px;
    right: 30px;
    cursor: pointer;
  }

  .Form_reg_div {
    padding: 24px;
  }


  .Form_reg_div h4 {
    color: #333333;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    line-height: 60px;
    font-size: 40px;
  }

  .Form_reg_div p {
    color: #333333;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    line-height: 27px;
    font-size: 18px;
  }

  .Reg_form_input_btn {
    padding: 16px 37px;
    width: 268px;
    box-shadow: 5px 5px 15px 0px #0000001A;
    border-radius: 10px;
    border: 1px solid #33333380;
    background: #1993FB;
    color: white;
    font-family: "Poppins", sans-serif;
    font-size: 18px;
  }

  .Reg_form_input {
    padding: 16px 50px;
    width: 268px;
    box-shadow: 5px 5px 15px 0px #0000001A;
    border-radius: 10px;
    border: 1px solid #33333380;
  }

  .Reg_form_input::placeholder {
    padding-left: 14px;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
  }

  .Reg_input_div {
    position: relative;
  }

  .Reg_form_input_icon {
    position: absolute;
    top: 38%;
    left: 10%;
    opacity: 33%;
  }

}

.loginauthicon {

  width: 72px;
  height: 72px;
  cursor: pointer;
}

.threeIcons {
  align-items: center !important;
}

.login_last_text {
  font-size: 14px !important;
}

.login-special_txt {
  color: #0575E6;
}

/* Umer Css starts here */
a {
  text-decoration: none;
}

.sidebar-link ul li a {
  display: inline-block;
  color: rgb(121, 121, 121);
}

.sidebar-link ul li.active-link a {
  color: #fff;
}

.sidebar-link ul li img {
  filter: invert(1) brightness(0.5);
}

.active-link {
  background-color: #0575E6;
  border-radius: 10px;
  padding: 15px 50px;
}

.sidebar-link ul li.active-link img {
  filter: invert(1) brightness(2);
}

.sidebar-link ul li a img {
  margin-right: 10px;
  font-size: 18px;
}

.sidebar-link ul li {
  padding: 15px 2.6vw;
}

.sidebar-main {
  height: 100%;
  padding: 0px 30px;
}

.sidebar-links {
  height: calc(100vh - 470px);
  overflow-y: auto;
  padding-right: 5px;
}

.sidebar-links::-webkit-scrollbar {
  width: 5px;
}

.sidebar-links::-webkit-scrollbar-track {
  background: #ffffff;
}

.sidebar-links::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #0575E6;
}

.sidebar-link ul li:last-child {
  margin-top: auto;
}


.toggle-sidebar {
  display: none;
}

.terms-search input {
  position: relative;
  padding: 10px;
  padding-left: 30px;
  padding-right: 5px;
  color: rgba(255, 255, 255, 0.808);
  border: 1px solid rgb(182, 179, 179);
  border-radius: 8px;
  width: 100%;
  left: -18px;
  z-index: 0;
}

.terms-search img {
  position: relative;
  top: 50%;
  left: 10px;
  z-index: 1;
}

.dropdown-pic {
  position: relative;
  height: 15px;
  left: 20px;
}


.terms-search input:focus {
  border: 1px solid rgb(75, 74, 74);
  color: #000;
}

.terms-detail h3 {
  font-size: 22px;
  color: rgba(0, 138, 255, 1);
}

.terms-detail p {
  font-size: 14px;
  color: rgba(51, 51, 51, 1);
}

.logo img {
  width: 161px;
  height: 56px;
}

.sidebar-logout button {
  border: none;
  background: transparent;
  color: rgb(121, 121, 121);
}

.sidebar-logout button img {
  filter: invert(1) brightness(0.5);
}

.sidebar-profile button img {
  width: 100px;
}

.sidebar-profile button {
  background: transparent;
  margin-top: 20px;
  border: none;
  width: fit-content;
}

.sidebar-link {
  height: 100%;
  margin-top: 20px;
}

.main-div {
  height: 100%;
  background-image: url('../src/assets/img/background-img.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.sidebar-background {
  width: 24vw;
  position: fixed;
  padding-top: 30px;
  height: 90vh;
  background: #fff;
  border-radius: 25px;
}

.sidebar-link ul {
  padding: 0px;
  margin: 0px;
  height: 97%;
}

.terms-banner {
  padding-right: 70px;
}

.terms-banner-icon {
  width: 100%;
}

.buy-now button {
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(25, 147, 251, 1);
  color: #000;
  padding: 16px 120px;
  border-radius: 10px;
}

.buy-now button:hover {
  background-color: rgba(25, 147, 251, 1);
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 1);
  transition: .4s;
}

.download button {
  background-color: rgba(25, 147, 251, 1);
  border: 1px solid rgba(255, 255, 255, 1);
  color: #fff;
  padding: 16px 120px;
  border-radius: 10px;
}

.download button:hover {
  background-color: rgba(255, 255, 255, 1);
  color: rgba(25, 147, 251, 1);
  border: 1px solid rgba(25, 147, 251, 1);
  transition: .4s;
}

.submit {
  margin-top: 16px;
}

.submit a {
  margin-top: 16px;
  background-color: rgba(25, 147, 251, 1);
  border: 1px solid rgba(255, 255, 255, 1);
  color: #fff;
  padding: 16px 120px;
  border-radius: 10px;
}

.submit a:hover {
  background-color: rgba(255, 255, 255, 1);
  color: rgba(25, 147, 251, 1);
  border: 1px solid rgba(25, 147, 251, 1);
  transition: .4s;
}

.select-design {
  padding: 0px 10px;
  margin-top: 0px;
  position: relative;
}

.select-design input {
  position: absolute;
  top: 15px;
  left: 90%;
}

.select-design img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Ensure the image covers the whole area */
  display: block;
  border-radius: 8px;
  /* Adjust as needed for rounded corners */
}

.select-design label {
  width: 100%;
}

.room-style-img {
  position: relative;
}

.check-mark {
  position: absolute;
  top: 4px;
  right: 12px;
  width: 20px !important;
  /* Adjust size as needed */
  height: 20px;
  /* Adjust size as needed */
}

.checkbox {
  display: none;
}

.image {
  border: 0px solid transparent;
  transition: border 0.3s ease;
}

.checkbox:checked+label .image {
  border: 1px solid rgba(25, 146, 250, 1);
}

.checkbox:checked {
  display: block;
}

.pick-search input {
  padding: 17px;
  border: 1px solid rgb(143, 142, 142);
  border-radius: 10px;
}

.pick-submit button {
  width: 100%;
  padding: 16px 2.8vw;
  background: rgba(25, 147, 251, 1);
  color: #fff;
  border-radius: 13px;
  border: 1px solid #fff;
}

.profile-img {
  margin-top: -50px;
}

.profile-name,
.profile-action {
  width: fit-content;
}

.profile-img img {
  width: 100%;
}

.profile-name p {
  color: rgb(172, 169, 169);
  font-size: 16px;
  margin-top: -8px;
}

.profile-name span {
  display: inline-block;
  background-color: rgba(20, 192, 108, 1);
  padding: 5px;
  width: 4px;
  height: 4px;
  border-radius: 100%;
}

.profile-action {
  display: flex;
  margin-top: 25px;
}

.edit-profile button {
  margin-right: 15px;
  border: none;
  background: transparent;
  font-weight: 600;
}

.edit-profile button img {
  margin-top: -5px;
  margin-right: 7px;
}

.upgrade-acc a {
  background-color: rgba(25, 147, 251, 1);
  color: #fff;
  border-radius: 10px;
  padding: 14px 25px;
}

.personal-info input {
  border: 1px solid rgba(51, 51, 51, 0.1);
  padding: 12px 20px;
  border-radius: 10px;
  width: 100%;
}

.personal-info {
  margin-top: 10px;
  margin-right: 20px;
}

.recent-project-div {
  padding: 0px 10px;
}

.recent-project-div img {
  width: 100%;
}

.recent-detail p {
  width: fit-content;
  margin-top: -2px;
  color: rgb(165, 163, 163);
  font-size: 1vw;
}

.recent-detail img {
  width: 1.3vw;
  height: 1.3vw;
}

.update-body input {
  padding: 13px 18px;
  background: #fff;
  border: none;
  border-radius: 10px;
}

.profile-update-modal {
  background: rgba(245, 245, 245, 1) !important;
}

.noti-status span {
  display: inline-block;
  background-color: rgba(20, 192, 108, 1);
  padding: 5px;
  width: 4px;
  height: 4px;
  border-radius: 100%;
}

.noti-message h5 {
  font-size: 16px;
  font-weight: 400;
}

.noti-message p {
  margin-top: -5px;
  font-size: 14px;
  font-weight: 400;
  color: rgb(138, 137, 137);
}

.noti-info h5 {
  font-size: 18px;
  font-weight: 500;
}

.noti-info p {
  color: rgba(25, 147, 251, 1);
  font-size: 14px;
  font-weight: 400;
  margin-top: -10px;
}

.noti-info {
  margin-top: 10px;
  margin-left: 15px;
}

.notification-table td {
  padding: 20px 1.3vw !important;
}

.notification-table {
  border-collapse: separate;
  border-spacing: 0;
}

.notification-table .table {
  border-radius: 1.3vw;
  overflow: hidden;
}

.notification-table .table thead {
  border-radius: 1.3vw 1.3vw 0 0;
  overflow: hidden;
}

.notification-table .table tbody {
  border-radius: 0 0 1.3vw 1.3vw;
  overflow: hidden;
}



.contact-infos {
  position: relative;
  height: 210px;
  text-align: -webkit-center;
  background-color: #fff;
  border-radius: 12px;
  border-bottom: 3px solid rgba(25, 147, 251, 1);
  padding: 20px 2.08vw;
}

.contact-icon {
  position: relative;
  background-color: rgba(25, 147, 251, 1);
  width: fit-content;
  padding: 22px;
  border-radius: 12px;
  top: -51px;
}

.contact-infos h4 {
  margin-top: -35px;
  margin-bottom: 25px;
}

.contact-form {
  border: 1px dashed rgba(51, 51, 51, 1);
  border-radius: 18px;
  padding: 10px;
}

.contact-inputs {
  padding-bottom: 20px;
}

.contact-inputs input {
  margin-top: 20px;
  border-bottom: 1px solid #cccbcb;
  padding: 10px 0px;
  width: 100%;
  border-top: none;
  border-right: none;
  border-left: none;
  background: transparent;

}

.contact-submit button {
  margin-top: 25px;
  background-color: rgba(25, 147, 251, 1);
  color: rgba(255, 255, 255, 1);
  padding: 12px 90px;
  border-radius: 10px;
  border: none;
}

.heading-form {
  border-radius: 13px;
  display: inline-block;
  transform: rotate(270deg);
  width: max-content;
  position: relative;
  top: 100px;
  right: 74px;
  background: #fff;
  padding: 21px 30px;
}

.heading-form h4 {
  display: inline-block;
}

.profile-pic {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: solid white;
}

.notification-icon {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: solid white;
  background-color: white;
  padding: 5px;
  transform-style: preserve-3d;
}

.notification-icon::after {
  content: "";
  width: 50px;
  height: 50px;
  transform: translateZ(-1px);
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  background-color: black;
  border-radius: 50%;
  filter: blur(20px) opacity(20%);
}

button {
  border: none;
  background: none;
}

.rounded-4 {
  border-radius: 1rem;
}

.upload-image {
  /* background-color: #fff; */
  height: 100%;
  border-radius: 12px;
}

.upload-img-border {
  border: 1px dashed #918e8e;
  height: 100%;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.upload-img-border img {
  width: 150px;
  height: 83px;
}

.room-type select {
  width: 100%;
  background: transparent;
  border-radius: 12px;
  border: 1px solid #969393;
  padding: 16px 20px;
}

.select-style {
  margin-top: 20px;
  padding: 16px 20px;
  border-radius: 12px;
  border: 1px solid #969393;
}

.room-style-img {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.room-style-img img {
  width: 83%;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.room-style-img p {
  font-size: 1vw;
  text-align: center;
  width: 83%;
  color: #000;
  background-color: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 0.3vw;
}

.main-content {
  min-height: 94vh;
  max-height: max-content;
}




@media only screen and (max-width: 992px) {

  .title h2 {
    font-size: 20px;
  }

  .toggle-sidebar {
    position: fixed;
    display: block;
    border: none;
    background: none;
    top: 10px;
  }

  .toggle-sidebar img {
    width: 20px;
  }

  @keyframes slide-in {
    from {
      transform: translateX(-200%);
    }

    to {
      transform: translateX(0%);
    }
  }

  @keyframes slide-out {
    from {
      transform: translateX(0%);
    }

    to {
      transform: translateX(-200%);
    }
  }

  .sidebar-background {
    width: auto;
    z-index: 1000;
  }

  .sidebar-background.open {
    animation-name: slide-in;
    animation-duration: 1s;
  }

  .sidebar-background.closing {
    animation-name: slide-out;
    animation-duration: 1s;
  }

  .sidebar-background.closed {
    transform: translateX(-200%);
  }

  .sidebar-profile button {
    width: 160px;
  }

  .sidebar-links-bottom {
    bottom: 100px;
  }

  .room-style-img img {
    width: 90%;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }

  .room-style-img p {
    font-size: 12px;
    text-align: center;
    width: 90%;
    color: #000;
    background-color: #fff;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 0.3vw;
  }

  .recent-detail p {
    width: fit-content;
    margin-top: -2px;
    color: rgb(165, 163, 163);
    font-size: 12px;
  }

  .recent-detail img {
    width: 15px;
    height: 15px;
  }
}

@media only screen and (max-width: 767px) {

  .upload-image {
    height: 400px;
  }

  .title h2 {
    font-size: 20px;
  }

  .terms-banner-icon {
    width: 290px;
  }

  .noti-status p {
    font-size: 10px;
  }

  .date {
    font-size: 10px;
  }

  .noti-message h5 {
    font-size: 11px;
  }

  .noti-message p {
    font-size: 10px;
  }

  .noti-info p {
    font-size: 10px;
  }

  .noti-info {
    margin-top: 10px;
    margin-left: 1.9vw;
  }

  .noti-info h5 {
    font-size: 12px;
  }

  .noti-info p {
    font-size: 10px;
  }

  .dropdown-pic {
    display: none;
  }

  .contact-infos {
    position: relative;
    height: 200px;
    text-align: -webkit-center;
    background-color: #fff;
    border-radius: 12px;
    border-bottom: 3px solid rgba(25, 147, 251, 1);
    padding: 20px 2.08vw;
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .contact-infos .mt4 {
    position: relative;
    height: 180px;
    text-align: -webkit-center;
    background-color: #fff;
    border-radius: 12px;
    border-bottom: 3px solid rgba(25, 147, 251, 1);
    padding: 20px 2.08vw;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .heading-form {
    border-radius: 13px;
    display: inline-block;
    transform: rotate(0deg);
    width: 100%;
    position: relative;
    top: 0px;
    right: 0px;
    background: #fff;
    padding: 21px 30px;
  }

  .select-design {
    display: flex;
    justify-content: center;
    padding: 10px 10px;
    margin-top: 0px;
    position: relative;
  }

  .profile-img img {
    width: 100px;
  }

  .profile-name h4 {
    font-size: 20px;
  }

  .profile-name p {
    font-size: 14px;
  }

  .profile-action {
    margin-top: 10px;
  }

  .upgrade-acc a {
    font-size: 10px;
  }

  .terms-search {
    margin-bottom: 15px;
  }

  .profile-div {
    width: 50px;
    height: 50px;
  }

}



@media only screen and (max-width: 375px) {
  .submit a {
    display: block;
    width: 100%;
    padding: 12px;
  }

  .contact-submit button {
    padding: 12px;
  }

  .noti-status p {
    font-size: 10px;
  }

  .date {
    font-size: 10px;
  }

  .noti-message h5 {
    font-size: 11px;
  }

  .noti-message p {
    margin-top: -5px;
    font-size: 10px;
  }

  .noti-info p {
    font-size: 10px;
    margin-top: -10px;
  }

  .noti-info {
    margin-top: 10px;
    margin-left: 0px;
  }

  .notification-table td {
    padding: 20px 1.3vw !important;
  }

  .noti-info h5 {
    font-size: 12px;
  }

  .noti-info p {
    font-size: 10px;
  }

  .dropdown-pic {
    display: none;
  }

  .buy-now button {
    padding: 16px;
    width: 100%;
  }

  .download button {
    padding: 16px;
    width: 100%;
  }

  .profile-action {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center !important;
  }

  .profile-action button,
  .profile-action a {
    margin: 10px;
  }

  .images img {
    width: 90%;
  }

  .title h2 {
    font-size: 16px;
  }

  .profile-name h4 {
    font-size: 16px;
  }

  .profile-name p {
    font-size: 10px;
  }

  .profile-action {
    margin-top: 0px;
  }

  .upgrade-acc a {
    margin: 0px;
    font-size: 8px;
    padding: 14px 10px;
  }

}