body {
  background-color: rgba(240, 239, 239, 0.685);
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}

#sidebarMenu {
  background-color: white;
  box-shadow: 5px 92px 20px 5px #0b0b0b0f;
  height: 100vh !important;
  position: sticky;
  top: 0;
  width: 100% !important;
  overflow-y: hidden;
}

#sidebarMenu:hover {
  overflow-y: scroll;
}

#sidebarMenu::-webkit-scrollbar {
  width: 4px;
}

#sidebarMenu::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 8px;
}

#sidebarMenu::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

#sidebarMenu::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 8px;
}

/* Main homepage css Start */

.dragdrop {
  height: 100%;
  background-color: white;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.3s all;
}

.dragdrop:hover {
  background-color: rgba(12, 123, 175, 0.075);
}

.dragdrop:active {
  background-color: rgba(12, 123, 175, 0.178);
}

.dragdrop div {
  border: 3px dashed rgba(0, 0, 0, 0.178);
  border-radius: 10px;
}

.dragdrop div i {
  font-size: 55px;
  color: rgb(94, 92, 92);
}

/* Login Page css start */
.login-shadow {
  background-color: rgba(0, 0, 0, 0.432);
  height: 250px;
  width: 100%;
  backdrop-filter: blur(4px);
  border-radius: 0 0 30px 0;
}

.step_3-img {
  border-radius: 15px !important;
}

.step_3-section {
  width: 700px;
}

/* Notifications Section Css */
.table-data {
  transition: 0.5s all;
}

.table-data:hover {
  background-color: #0000000a;
}

.table-data:active {
  background-color: #00000025;
}

@media screen and (max-width: 700px) {
  .login-form {
    width: 100% !important;
  }
  .back-img {
    display: none !important;
  }
  .main-login-form {
    height: auto !important;
    padding: 0 14px !important;
  }
  .social-logo {
    width: 35px !important;
  }
}
.btn.btn-primary.authbtn {
  height: 40px;
}