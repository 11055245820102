@import url("https://fonts.googleapis.com/css2?family=Signika:wght@500&display=swap");

.rounded-5 {
  border-radius: 15px;
}

.text-light-1 {
  color: rgba(8, 8, 8, 0.589) !important;
  font-weight: 500;
}

.cr-pointer {
  cursor: pointer;
}

.text-primary {
  color: #2ac5e8 !important;
}

.text-primary-dark {
  color: #0096c7 !important;
}

.fw-5 {
  font-weight: 500;
}

.pd-10 {
  padding: 10px !important;
}

.fs-sm {
  font-size: 13px;
}

.bg-lighth-blue {
  background-color: rgba(73, 134, 226, 0.137);
}

.active-tab {
  background: #2ac5e82f;
  border-radius: 10px;
  color: rgba(2, 2, 2, 0.911) !important;
  font-weight: 600;
  box-shadow: 0px 11px 20px 0px #0000000f;
}

.active-terms-link {
  color: #067da5 !important;
}

.nav-link:hover {
  background: #0000000a;
  border-radius: 10px !important;
}

.font-family {
  font-family: "Signika", sans-serif;
}

.active-tab .material-icons {
  background-color: #0096c7;
  color: white !important;
  font-size: 18px;
}

.py-sm {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.rounded-5 {
  border-radius: 8px !important;
}

.form-control {
  height: 40px !important;
  border-radius: 8px;
}

.form-control:focus {
  box-shadow: none;
  border: 2px solid rgb(12, 123, 175);
}

.footer {
  position: relative;
  width: 100%;
  bottom: 0px;
}

.overlay-card {
  height: 280px !important;
  border-radius: 10px;
  transition: 0.4s all;
}

.overlay-card div {
  background: linear-gradient(to top, #00000011, #00000078);
  border-radius: 10px;
  height: 100%;
}

.overlay-card:hover {
  transform: scale(1.01);
}

.btn-primary {
  background: linear-gradient(to right, #12aed1, #2ac5e8) !important;
  border: none !important;
  padding: 10px 20px !important;
  border-radius: 8px;
}

.btn-primary:focus {
  box-shadow: 0 0 0 5px #2ac5e84d;
}

.btn-secondary {
  background-color: rgb(216, 213, 213);
  border: 1px solid rgb(190, 190, 190);
  color: black;
  font-weight: 500;
  border-radius: 8px;
  padding: 10px 20px;
}

.py-md {
  padding: 8px 18px !important;
}

.dropdown-item:active {
  background-color: rgba(46, 46, 46, 0.192) !important;
  color: black !important;
}

.latest-upload-project-img {
  min-height: 150px;
}

.upload-project-card {
  border: 4px dashed rgba(14, 14, 14, 0.144);
  cursor: pointer;
  height: 100%;
  border-radius: 10px;
  transition: 0.3s all;
}

.upload-project-card:hover {
  background-color: rgba(174, 226, 250, 0.116);
}

/* Mlti Steps FOrgot Password */
.forgot-password-steps {
  height: 100vh;
  background-color: rgba(240, 239, 239, 0.685);
}

.forgot-password-steps .forgot-password-card {
  background-color: white;
  border-radius: 10px;
}

.borderLess-input {
  border: none !important;
  border-bottom: 1px solid rgba(14, 13, 13, 0.11) !important;
  border-radius: 0px !important;
  background-color: rgba(240, 239, 239, 0.685) !important;
}

/*  Room type Card */
.room-type-card img {
  min-height: 150px;
  width: 100% !important;
  border-radius: 15px;
}

.room-type-card {
  border-radius: 15px;
  border: 1px solid transparent;
}

.room-type-card i {
  right: 10px;
  top: 10px;
  color: #067da5;
}

/* Steps Css */
.steps .step {
  border-radius: 50%;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.steps .step-color {
  background-color: #0096c7;
  color: white !important;
}

.steps .step-light {
  background-color: #00000017;
}

.steps .border {
  width: 85px;
  /* padding: 1px; */
  border: 2px dashed rgba(0, 0, 0, 0.788) !important;
  position: relative;
  top: -27px;
}

.light-blue {
  background-color: #147d9512;
}

.light-yellow {
  background-color: #ffff0040;
}

.light-green {
  background-color: #00800021;
}

.dark-blue {
  color: #0073ffbc !important;
}

.dark-yellow {
  color: #c1c100 !important;
}

.dark-green {
  color: #003800a2 !important;
}

.sidebar-dragdrop {
  height: 90px;
  background-color: white;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.3s all;
  border: 3px dashed rgba(0, 0, 0, 0.178);
}

.sidebar-dragdrop:hover {
  background-color: rgba(12, 123, 175, 0.075);
}

.sidebar-dragdrop:active {
  background-color: rgba(12, 123, 175, 0.178);
}

/* .sidebar-dragdrop div {
  border-radius: 10px;
} */

.sidebar-dragdrop div i {
  color: rgb(94, 92, 92);
}

@media screen and (max-width: 1200px) {
  .main-content {
    /* width: 100% !important; */
  }
  .main-sidebar {
    width: 0% !important;
  }
  #sidebarMenu {
    display: none !important;
  }
  .sidebar-toggle {
    display: block !important;
  }
}

@media screen and (max-width: 500px) {
  .forgot-password-steps {
    height: auto !important;
  }
  .forgot-password-card {
    padding: 35px 12px !important;
    width: 100% !important;
    border-radius: 0 !important;
  }
  .logo {
    width: 140px;
  }
}
.forgetbtn {
  height: 40px;
  width: 80px;
}
.forgetbtn .spinner-border {
  width: 25px;
  height: 25px;
}
.authbtn .spinner-border {
  width: 24px;
  height: 24px;
  position: relative;
  top: 2px;
}
@media (min-width: 200px) and (max-width: 360px) {
  .profileText{
    margin: 0px !important;
  }
}
@media (min-width: 200px) and (max-width: 600px) {
  .productImages{
    flex-wrap: wrap;
  }
}
.productImages .img-fluid{
  object-fit: cover;
}
.product_container {
  height: 400px;
  display: flex;
  width: 400px;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.pointer_cursor{
  cursor: pointer;
}
.active_room{
  border: 1px solid #2ac5e8 !important;
}
.generatebtn.authbtn {
  padding: 7px 33px !important;
  width: 117px;
  height: 38px;
}
.account_icon {
  font-size: 32px !important;
}

.disabled{
  height: 24vh;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin-bottom: 3rem;
}
img.selectedImg {
  object-fit: cover;
}
svg.ReactCrop__crop-mask {
  border-radius: 15px;
}
.ReactCrop {
  box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
  border: 1px solid #2ac5e8 !important;
  border-radius: 15px;
}
.room-type-card.productInfo img{
    height: 230px;
    width: 200px !important;
}
.productContent{
  display: -webkit-box;
  vertical-align: middle;
  text-overflow: ellipsis;
  width: 100%;
  text-align: left;
  height: 4em;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-top: 4px;
  margin-bottom: 0px !important;
}
.productPrice.deactivate {
  color: #8f8a8a;
}
.productPrice.deactivate {
  color: #8f8a8a;
  font-size: 15px;
  font-weight: 100;
}
.profileBtn {
  width: 136px;
}
.profileBtn .spinner-border {
  width: 20px;
  height: 20px;
  position: relative;
  top: 3px;
}
#countryCode {
  width: 110px;
  max-width: 110px;
}
.form-select:focus{
  box-shadow:unset
}
.contactusBtn .spinner-border {
  height: 20px;
  width: 20px;
}
.contactusBtn {
  padding: 10px 35px !important;
}
.row.deactivate.history,.activate_notifiy,.deactivate_content {
  display: flex;
  min-height: 525px;
}
.croperContainer {
  min-height: 281px;
}
.latest-upload-project-img {
  min-height: 230px;
  height: 230px;
  object-fit: cover;
}
.deactivate.notifiy {
  height: calc(100vh - 205px);
  display:flex;
}
.text-clamp{
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  width: 100%;
}
.disabled_activate{
  justify-content: center;
  text-align: center;
  align-items: center;
  margin-bottom: 3rem;
}
.deactivate_notifiy {
  height: calc(100vh - 181px);
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.activateNotifiy {
  height: calc(100vh - 229px);
}
.searchResult {
  height: calc(100vh - 205px);
}
.search_not_found {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.search-page {
  min-height: 525px;
}
.slick-track {
  display: flex !important;
}
.form-control.promt {
  height: 100px !important;
}
sup.optional {
  font-size: 13px;
  font-weight: 400;
}

.spinner-border {
  height: 1rem;
  width: 1rem;
}
.disabled_activate .spinner-border.text-dark{
  height: 2rem;
  width: 2rem;
}
.imageGen .spinner-border {
  height: 20px;
  width: 20px;
}
.croperImg{
  object-fit:unset;
}
img.selectedImg.croperImg {
  object-fit:unset;
}
/* .croperContainerh {
  display: none !important;
} */
.container.mt-5.croperContainerh {
  position: absolute;
  left: 100%;
  top: 0;
}
@media (min-width: 1200px) {
  .h4, h4 {
      font-size: 1.3rem;
  }
}